import {
  type PayoutReconciliationStatus,
  type PayoutStatus,
  type PayoutTransactionSubjectType,
  type PayoutTransactionType,
  type PayoutType,
} from '@/features/billing/types'

import {
  type PayoutKeys,
  type TransactionKeys,
} from '../components/payouts/payout-export/payoutExportUtils'

export interface BillingEn {
  payout: {
    export: {
      payoutHeaders: Record<PayoutKeys, string>
      transactionHeaders: Record<TransactionKeys, string>
      sections: Record<string, string>
    }
    type: Record<PayoutType, string>
    status: Record<PayoutStatus, string>
    reconciliationStatus: Record<PayoutReconciliationStatus, string>
    transaction: {
      type: Record<PayoutTransactionType, string>
      subject: Record<PayoutTransactionSubjectType, string>
    }
  }
}

export const billingEn: BillingEn = {
  payout: {
    export: {
      payoutHeaders: {
        adjustments: 'Adjustments',
        arrivalDate: 'Arrival Date',
        chargebacks: 'Chargebacks',
        delinquencyFees: 'Delinquency Fees',
        deliveryFeesCollected: 'Delivery Fees Collected',
        deliveryFeesDistributed: 'Delivery Fees Distributed',
        discounts: 'Discounts',
        giftCard: 'Gift Card Redemptions',
        grossRevenue: 'Gross Revenue',
        inHouseTips: 'In-House Tips',
        initiatedDate: 'Initiated Date',
        locations: 'Locations',
        netPayout: 'Total',
        netRevenue: 'Net Revenue',
        otherRevenueFees: 'Other Fees',
        otherRevenueTotal: 'Other Revenue Total',
        ownerFees: 'Owner Fees',
        ownerFeesCollected: 'Owner Fees Collected',
        processingFeesCollected: 'Processing Fees Collected',
        processingFeesDistributed: 'Processing Fees Distributed',
        reconciliationStatus: 'Reconciliation Status',
        subtotal: 'Subtotal',
        taxes: 'Taxes',
        thirdPartyTipsCollected: 'Third Party Tips Collected',
        thirdPartyTipsDistributed: 'Third Party Tips Distributed',
      },
      sections: {
        payout: 'Payout',
        'payout-not-reconciled':
          'This payout is still being reconciled.  Check back later for more complete details.',
        transactions: 'Transactions',
      },
      transactionHeaders: {
        chargebacks: 'Chargebacks',
        delinquencyFees: 'Delinquency Fees',
        deliveryFeesCollected: 'Delivery Fees Collected',
        deliveryFeesDistributed: 'Delivery Fees Distributed',
        discounts: 'Discounts',
        giftCard: 'Gift Card Redemptions',
        grossRevenue: 'Gross Revenue',
        inHouseTips: 'In-House Tips',
        location: 'Location',
        netPayout: 'Total',
        netRevenue: 'Net Revenue',
        ownerFees: 'Owner Fees',
        ownerFeesCollected: 'Owner Fees Collected',
        processingFeesCollected: 'Processing Fees Collected',
        processingFeesDistributed: 'Processing Fees Distributed',
        subjectType: 'Source',
        subtotal: 'Subtotal',
        taxes: 'Taxes',
        thirdPartyTipsCollected: 'Third Party Tips Collected',
        thirdPartyTipsDistributed: 'Third Party Tips Distributed',
        transactionDate: 'Transaction Date',
        type: 'Type',
      },
    },
    reconciliationStatus: {
      complete: 'Complete',
      failed: 'Unreconcilable',
      not_applicable: 'Not Applicable',
      pending: 'Pending',
    },
    status: {
      canceled: 'Canceled',
      failed: 'Failed',
      in_transit: 'In Transit',
      paid: 'Paid',
      pending: 'Pending',
    },
    transaction: {
      subject: {
        external: 'External',
        'gift-card': 'Gift Card',
        order: 'Order',
      },
      type: {
        adjustment: 'Adjustment',
        advance: 'Advance',
        'advance-funding': 'Advance Funding',
        'anticipation-repayment': 'Anticipation Repayment',
        'application-fee': 'Application Fee',
        'application-fee-refund': 'Application Fee Refund',
        charge: 'Charge',
        'climate-order-purchase': 'Climate Order Purchase',
        'climate-order-refund': 'Climate Order Refund',
        'connect-collection-transfer': 'Connect Collection Transfer',
        contribution: 'Contribution',
        dispute: 'Dispute',
        'dispute-won': 'Dispute Won',
        'financing-paydown': 'Financing Paydown',
        'financing-paydown-reversal': 'Financing Paydown Reversal',
        'financing-payout': 'Financing Payout',
        'financing-payout-reversal': 'Financing Payout Reversal',
        'issuing-authorization-hold': 'Issuing Authorization Hold',
        'issuing-authorization-release': 'Issuing Authorization Release',
        'issuing-dispute': 'Issuing Dispute',
        'issuing-transaction': 'Issuing Transaction',
        'obligation-outbound': 'Obligation Outbound',
        'obligation-reversal-inbound': 'Obligation Reversal Inbound',
        payment: 'Payment',
        'payment-failure-refund': 'Payment Failure Refund',
        'payment-network-reserve-hold': 'Payment Network Reserve Hold',
        'payment-network-reserve-release': 'Payment Network Reserve Release',
        'payment-refund': 'Payment Refund',
        'payment-reversal': 'Payment Reversal',
        'payment-unreconciled': 'Unreconciled Payment',
        payout: 'Payout',
        'payout-cancel': 'Payout Cancel',
        'payout-failure': 'Payout Failure',
        refund: 'Refund',
        'refund-failure': 'Refund Failure',
        'reserve-transaction': 'Reserve Transaction',
        'reserved-funds': 'Reserved Funds',
        'stripe-fee': 'Stripe Fee',
        'stripe-fx-fee': 'Stripe FX Fee',
        'tax-fee': 'Tax Fee',
        topup: 'Topup',
        'topup-reversal': 'Topup Reversal',
        transfer: 'Transfer',
        'transfer-cancel': 'Transfer Cancel',
        'transfer-failure': 'Transfer Failure',
        'transfer-refund': 'Transfer Refund',
        unknown: 'Unknown',
      },
    },
    type: {
      automatic: 'Automatic',
      instant: 'Instant',
    },
  },
}
