<template>
  <div class="flex-row gap-4 justify-between">
    <OwnType
      class="flex-shrink-0"
      :text="label"
      :variant="labelVariant"
      :color="labelColor"
      :info="labelInfo"
    />

    <div class="flex-row gap-2">
      <slot name="description" />

      <OwnType
        :text="value"
        :variant="valueVariant"
        :color="valueColor"
        :title="valueTitle"
        :tabular="currency"
        align="right"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type FontColor, OwnType } from '@/ui'

const props = withDefaults(
  defineProps<{
    currency?: boolean
    label: string
    labelInfo?: string
    value: string
    valueColor?: FontColor
    valueTitle?: string
    variant?: 'total' | 'subtotal' | 'body'
  }>(),
  {
    currency: false,
    labelInfo: undefined,
    valueColor: undefined,
    valueTitle: undefined,
    variant: undefined,
  }
)

const labelVariant = computed(() => {
  switch (props.variant) {
    case 'body':
      return 'paragraph'
    case 'subtotal':
      return 'paragraph'
    case 'total':
      return 'subtitle'
    default:
      return 'subtitle'
  }
})

const labelColor = computed(() => {
  if (props.variant === 'body') return 'secondary'
  return 'primary'
})

const valueVariant = computed(() => {
  switch (props.variant) {
    case 'body':
      return 'paragraph'
    case 'subtotal':
      return 'paragraph'
    case 'total':
      return 'subtitle'
    default:
      return 'paragraph'
  }
})

const valueColor = computed(() => {
  if (props.valueColor) return props.valueColor

  if (props.variant === 'body') return 'secondary'
  return 'primary'
})
</script>
