import { type RouteLocationNamedRaw, useRouter } from 'vue-router'

export interface UseOrderRouter {
  navToMainPage: () => Promise<void>
  navToDetailPage: (
    orderId: string,
    returnTo?: RouteLocationNamedRaw
  ) => Promise<void>
}

export const ORDER_PREFIX = 'brand.orders'

export const useOrderRouter = (): UseOrderRouter => {
  const router = useRouter()

  const navToMainPage = async (): Promise<void> => {
    await router.push({
      name: ORDER_PREFIX,
    })
  }

  const navToDetailPage = async (
    orderId: string,
    returnTo?: RouteLocationNamedRaw
  ): Promise<void> => {
    await router.push({
      name: `${ORDER_PREFIX}.detail`,
      params: {
        orderId,
      },
      query: {
        returnTo: returnTo ? window.btoa(JSON.stringify(returnTo)) : undefined,
      },
    })
  }

  return {
    navToDetailPage,
    navToMainPage,
  }
}
