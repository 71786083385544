<template>
  <div class="own-skeleton-loader">
    <!--
      @slot default - Loader content to infer width/height from
    -->
    <slot>
      <div class="own-skeleton-loader__load-container" :style="loaderStyles">
        <div class="own-skeleton-loader__shimmer-box"></div>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'OwnSkeletonLoader',
  props: {
    /** Sets the height of the skeleton loader */
    height: { type: [String, Number], default: undefined },

    /** Sets the width of the skeleton loader */
    maxWidth: { type: [String, Number], default: undefined },
  },
  computed: {
    loaderStyles() {
      const { height, maxWidth } = this

      return {
        height: height ? `${height}px` : undefined,
        maxHeight: height ? `${height}px` : undefined,
        maxWidth: maxWidth ? `${maxWidth}px` : undefined,
      }
    },
  },
}
</script>
<style lang="scss">
.own-skeleton-loader {
  width: 100%;

  &__load-container {
    position: relative;
    overflow: hidden;
    background-color: var(--background-secondary);
    border-radius: 8px;
  }

  &__shimmer-box {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    animation: wave 1.6s linear 0.5s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      var(--background-secondary),
      transparent
    );
  }

  @keyframes wave {
    0% {
      transform: translateX(-100%);
    }
    50% {
      /* +0.5s of delay between each loop */
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
