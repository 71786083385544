import { careersRouter } from '@/features/careers/router/careers.router'
import { locationReportRoutes } from '@/features/reports/router/reports.router'
import { settingsRouterLocation } from '@/features/settings/router/settings.router'
import { applyMetaToChildren } from '@/utils/router'

export const locationRoutes = applyMetaToChildren(
  [
    {
      children: [
        {
          path: '',
          redirect: 'dashboard',
        },
        ...locationReportRoutes,
        ...careersRouter,
        ...settingsRouterLocation,
      ],
      component: async () =>
        await import('@/views/BrandView/LocationViews/LocationRouterView.vue'),
      path: 'locations/:locationId',
      props: true,
    },
  ],
  {
    acl: {
      Location: 'locationId',
    },
  }
)
