import { logError } from '@/logger'

/**
 * Get a human-readable description of the current timezone, at the current time, localized in the browser's current
 * locale.
 * @description Use this instead of `Intl.DateTimeFormat().resolvedOptions().timeZone`, which returns e.g.
 * "America/Chicago".
 * @returns Timezone name, e.g. "Central Standard Time (CST)".
 */
export function getLocalizedTimezone(): string {
  const shortName = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
    .formatToParts()
    .find(({ type }) => type === 'timeZoneName')?.value

  const longName = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' })
    .formatToParts()
    .find(({ type }) => type === 'timeZoneName')?.value

  // This is only to satisfy the typechecker
  if (shortName === undefined || longName === undefined) {
    logError(
      'Intl.DateTimeFormat.formatToParts did not return a timeZoneName part'
    )
    return ''
  }

  return `${longName} (${shortName})`
}
