<i18n locale="en">
{
  "headers": {
    "name": "Name",
    "email": "Email",
    "phone": "Phone Number",
    "id": "Submission ID",
    "created-at": "Date Received",
    "text-field": "Text Field",
    "select": "Choose One",
    "multi-select": "Choose Multiple"
  },
  "error": "Could not load submissions.",
  "no-data": "No submissions for this form.",
  "loading": "Loading submissions...",
  "no-response": "No response given"
}
</i18n>

<template>
  <div class="flex-col gap-4">
    <OwnTable
      :columns="columns"
      :items="displayItems"
      item-key="id"
      :query="query"
      @click="handleClick"
    >
      <template v-if="isLoading" #loading>
        <DefaultComponentLoader :message="t('loading')" />
      </template>

      <template #no-data>
        <DefaultEmptyState :message="t('no-data')">
          <template #icon>
            <PhTextbox size="24" />
          </template>
        </DefaultEmptyState>
      </template>

      <template #card="{ item }">
        <div class="flex-row gap-2 align-center">
          <div v-if="!item.read" class="submissions-table__unread"></div>
          <OwnType
            :class="[!item.overallTitle && 'text--italic']"
            :text="item.overallTitle || t('no-response')"
            variant="subtitle"
            color="primary"
          />
        </div>
      </template>

      <template #item="{ item, isFirst, columnKey }">
        <div class="flex-row gap-3 align-center">
          <div
            v-if="!item.read && isFirst"
            class="submissions-table__unread"
          ></div>
          <OwnType
            :class="[!item[columnKey] && 'text--italic']"
            :text="item[columnKey] ?? t('no-response')"
            variant="subtitle"
            :color="isFirst ? 'primary' : 'secondary'"
          />
        </div>
      </template>
    </OwnTable>

    <PaginationControls
      v-model:request-params="requestParams"
      v-model:request-data="requestData"
      :loading="isLoading"
    />

    <SubmissionDetailModal
      v-model="showDetail"
      :sections="sections"
      :submission="detailSubmission"
      @cancel="detailSubmission = null"
      @unread="onMarkDetailUnread"
    />
  </div>
</template>

<script>
import { PhTextbox } from '@phosphor-icons/vue'
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import DefaultEmptyState from '@/components/empty/DefaultEmptyState.vue'
import { DefaultComponentLoader } from '@/components/loaders'
import { PaginationControls, usePaginationData } from '@/components/table'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnTable, OwnType } from '@/ui'

import SubmissionDetailModal from './submission-detail/SubmissionDetailModal.vue'

export default {
  name: 'FormBuilderSubmissionsTable',
  components: {
    DefaultComponentLoader,
    DefaultEmptyState,
    OwnTable,
    OwnType,
    PaginationControls,
    PhTextbox,
    SubmissionDetailModal,
  },
  mixins: [notify],
  data() {
    return {
      detailSubmission: null,
      isLoading: false,
      items: [],
      showDetail: null,
    }
  },
  setup() {
    const { t } = useI18n()
    const { requestParams, requestData, query } = usePaginationData()

    return {
      query,
      requestData,
      requestParams,
      t,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
      currentResource: 'formBuilder/currentResource',
    }),
    brandId() {
      const { currentResource } = this

      return currentResource ? currentResource.brandId : null
    },
    columns() {
      const { fields } = this

      const fieldHeaders = fields.map((header) => ({
        ...header,
        body: {
          color: 'primary',
          font: 'subtitle',
        },
        justify: 'left',
        label: this.t(`headers.${header.type}`),
      }))

      return [
        ...fieldHeaders,
        {
          body: {
            color: 'primary',
            font: 'subtitle',
          },
          justify: 'left',
          key: 'formattedCreatedAt',
          label: this.t('headers.created-at'),
        },
      ]
    },
    displayItems() {
      const { items, columns } = this

      return items.map((item) => {
        const values = Object.entries(item.data).reduce(
          (accValues, [key, response]) => ({
            ...accValues,
            [key]: response?.value || null,
          }),
          {}
        )

        return {
          ...item,
          ...values,
          formattedCreatedAt: format(new Date(item.createdAt), 'MMM do yyyy'),
          overallTitle: values[columns[0].key] || null,
        }
      })
    },
    fields() {
      const { keyFields } = this

      const fields =
        keyFields.length > 0
          ? keyFields.map(({ id, type }) => ({
              key: id,
              type,
            }))
          : [
              {
                key: 'id',
                type: 'id',
              },
            ]

      return fields
    },
    form() {
      const { currentResource } = this

      return currentResource
    },
    formId() {
      const { form } = this

      return form ? form.id : null
    },
    header() {
      const { form } = this

      return form ? this.t('header', { title: form.title }) : ''
    },
    keyFields() {
      const { sections } = this

      const nameField = sections.find(({ type }) => type === 'name')
      const phoneField = sections.find(({ type }) => type === 'phone')
      const emailField = sections.find(({ type }) => type === 'email')

      const foundKeyFields = [nameField, phoneField, emailField].filter(
        (field) => !!field
      )

      if (foundKeyFields.length > 0) {
        return foundKeyFields
      }

      const firstSection = sections[0]

      if (firstSection) {
        return [firstSection]
      }

      return []
    },
    sections() {
      const { form } = this

      if (!form) return []

      const sectionIds = form.sections
      const sections = sectionIds.map(this.blockById)

      return sections
    },
  },
  watch: {
    async query() {
      this.requestParams.page = 1
      await this.loadSubmissions()
    },
    requestParams: {
      deep: true,
      async handler() {
        await this.loadSubmissions()
      },
    },
  },
  async created() {
    this.isLoading = true
    await this.loadSubmissions()
    this.isLoading = false
  },
  methods: {
    handleClick(submission) {
      this.showDetail = true
      this.detailSubmission = submission
      this.items = this.items.map((item) =>
        item.id === submission.id
          ? {
              ...item,
              read: true,
            }
          : item
      )
    },
    async loadSubmissions() {
      const { formId, brandId, requestParams, query } = this

      try {
        const response =
          await ConfiguredClient.forms.v1.submissions.getSubmissions({
            query: {
              brandId,
              formId,
              limit: requestParams.limit,
              page: requestParams.page,
              query,
              sort: ['createdAt:desc'],
            },
          })

        this.items = response.results
        this.requestData.pageCount = response.pageCount
        this.requestData.totalItems = response.total
      } catch (error) {
        logError(error)
        this.$notify(this.t('error'), 'error')
      }
    },
    onMarkDetailUnread() {
      const { detailSubmission } = this

      this.items = this.items.map((item) =>
        item.id === detailSubmission.id
          ? {
              ...item,
              read: false,
            }
          : item
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.submissions-table {
  &__unread {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--misc-brand);
  }
}
</style>
