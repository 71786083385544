<template>
  <div
    :class="[
      'flex-shrink-0',
      isDesktop ? 'flex-row gap-14' : 'flex-col gap-6',
      'justify-between',
    ]"
  >
    <div v-if="title || subtitle || $slots['subtitle']" class="flex-col gap-2">
      <OwnType v-if="title" :text="title" variant="heading" />

      <slot name="subtitle">
        <OwnType
          v-if="subtitle"
          class="page-header__subtitle"
          :text="subtitle"
          variant="paragraph"
        />
      </slot>
    </div>

    <div v-if="$slots['actions']" class="page-header__actions">
      <div :class="[isDesktop ? 'flex-row' : 'flex-col-reverse', 'gap-4']">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { OwnType } from '@/ui'
import { useBreakpoints } from '@/utils'

withDefaults(
  defineProps<{
    subtitle?: string
    title?: string
  }>(),
  {
    subtitle: undefined,
    title: undefined,
  }
)

const { isDesktop } = useBreakpoints()
</script>

<style lang="scss" scoped>
.page-header {
  &__actions {
    flex-shrink: 0;
  }

  &__subtitle {
    max-width: 640px;
  }
}
</style>
