import { sprig } from '@sprig-technologies/sprig-browser'
import type { Plugin } from 'vue'

export const Sprig = import.meta.env.VITE_SPRIG_ENVIRONMENT_ID
  ? sprig.configure({
      environmentId: import.meta.env.VITE_SPRIG_ENVIRONMENT_ID,
    })
  : undefined

export const SprigPlugin: Plugin = {
  async install(app) {
    app.config.globalProperties.$sprig = Sprig
  },
}
