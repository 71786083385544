<i18n locale="en">
{
  "actions": {
    "add-existing-job": "Add Existing Job",
    "add-new-job": "Add New Job"
  },
  "errors": {
    "load": "Unable to load job titles"
  },
  "labels": {
    "heading": "Title",
    "jobs": "Jobs",
    "subheading": "Description"
  },
  "placeholders": {
    "heading": "Aa",
    "subheading": "Aa"
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer
      :label="t('labels.heading')"
      :errors="heading.status.errors"
      :max-chars="MAX_HEADING_LENGTH"
      :value="heading.value"
    >
      <OwnInput
        v-model="heading.value"
        :placeholder="t('placeholders.heading')"
      />
    </OwnInputContainer>
    <OwnInputContainer
      :label="t('labels.subheading')"
      :errors="subheading.status.errors"
      :max-chars="MAX_SUBHEADING_LENGTH"
      :value="subheading.value"
    >
      <OwnTextarea
        v-model="subheading.value"
        :placeholder="t('placeholders.subheading')"
      />
    </OwnInputContainer>
    <div class="flex-col gap-3">
      <OwnInputContainer :label="t('labels.jobs')">
        <Reorderable
          v-slot="{ item, index }"
          :model-value="jobs"
          class="space-y-3"
          @reorder="onReorder"
        >
          <OwnOption el="div">
            <div class="flex-row gap-4">
              <DragHandle />
              <div class="flex-col flex-1">
                <OwnType
                  :text="item.title"
                  variant="subtitle"
                  color="primary"
                />
              </div>

              <JobActionMenu
                :item="item"
                @action="onActionSelect($event, index, item)"
              />
            </div>
          </OwnOption>
        </Reorderable>
      </OwnInputContainer>

      <div class="flex-row gap-3">
        <AddJobButton
          :text="t('actions.add-existing-job')"
          @click="addingExistingJob = true"
        />
        <AddJobButton
          :text="t('actions.add-new-job')"
          @click="creatingNewJob = true"
        />
      </div>

      <AddExistingJobDialog
        :items="availableJobs"
        :value="addingExistingJob"
        @update="onAddExistingJob"
        @close="addingExistingJob = false"
      />
      <NewJobDialog
        :navigate="false"
        :show-include-on-website="false"
        :show-location="true"
        :model-value="creatingNewJob"
        @update:model-value="creatingNewJob = false"
        @created="onAddNewJob"
      />
      <EditJobDialog
        v-if="jobToEdit"
        :navigate="false"
        :job-id="jobToEdit"
        :model-value="jobToEdit !== null"
        @update:model-value="jobToEdit = null"
        @submit="onJobEdited"
      />
    </div>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { EditJobDialog, NewJobDialog } from '@/features/careers'
import { FormBuilder, Validators } from '@/forms'
import { builderProducer } from '@/lib/builder'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import {
  OwnOption,
  OwnInput,
  OwnInputContainer,
  OwnTextarea,
  OwnType,
  DragHandle,
  Reorderable,
} from '@/ui'

import { CareersCommands } from '../commands/CareersCommands'

import AddExistingJobDialog from './AddExistingJobDialog'
import AddJobButton from './AddJobButton'
import JobActionMenu from './JobActionMenu.vue'

const MAX_HEADING_LENGTH = 50
const MAX_SUBHEADING_LENGTH = 200

export default {
  name: 'CareersEditor',
  components: {
    AddExistingJobDialog,
    AddJobButton,
    DragHandle,
    EditJobDialog,
    JobActionMenu,
    NewJobDialog,
    OwnInput,
    OwnInputContainer,
    OwnOption,
    OwnTextarea,
    OwnType,
    Reorderable,
  },
  mixins: [
    builderProducer,
    notify,
    FormBuilder({
      heading: {
        debounce: 250,
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_HEADING_LENGTH),
        ],
      },
      subheading: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.maxLength(MAX_SUBHEADING_LENGTH)],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      MAX_HEADING_LENGTH,
      MAX_SUBHEADING_LENGTH,
      addingExistingJob: false,
      allJobs: [],
      creatingNewJob: false,
      jobToEdit: null,
      jobsById: {},
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      blockById: 'websiteBuilder/blockById',
    }),
    availableJobs() {
      const { allJobs, currentBlockParams } = this

      return allJobs.filter(({ id }) => !currentBlockParams?.jobs?.includes(id))
    },
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    currentBlockParams() {
      const { currentBlock } = this

      return currentBlock?.params
    },
    jobs() {
      const { currentBlockParams, jobsById } = this

      return currentBlockParams?.jobs.map(
        (jobId) => jobsById[jobId] ?? { id: jobId, title: jobId }
      )
    },
  },
  created() {
    const { currentBlockParams } = this

    this.fetchJobs()

    this.setInitialFormValues({
      heading: currentBlockParams.heading,
      subheading: currentBlockParams.subheading,
    })
  },
  methods: {
    async applyCareersUpdate(newValue) {
      const { id } = this

      await CareersCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    async fetchJobs() {
      const { activeBrandId } = this
      const limit = 100
      try {
        const response =
          await ConfiguredClient.dashboard.v1.brands.brand.careers.getJobs({
            query: {
              brandId: activeBrandId,
              limit,
            },
          })

        this.allJobs = response.results
        this.jobsById = keyBy(response.results, 'id')

        if (response.total > limit) {
          logError(
            new Error(
              `Brand "${activeBrandId}" has more than ${limit} Jobs, time to fix this pagination logic`
            )
          )
        }
      } catch (err) {
        logError(err)
        return this.$notify(this.t('errors.load'), 'error')
      }
    },
    async onActionSelect(event, index, item) {
      if (event.action === 'delete') {
        await CareersCommands.deleteJob(this.currentBlock, index)
      } else if (event.action === 'edit') {
        this.jobToEdit = item.id
      }
    },
    async onAddExistingJob(id) {
      // No more existing jobs are available, so adding a new job
      if (!id) {
        this.addingExistingJob = false
        this.creatingNewJob = true
        return
      }

      await CareersCommands.addJob(this.currentBlock, id)
    },
    async onAddNewJob(item) {
      await this.fetchJobs()
      this.sendBuilderEvent('jobs', { topic: 'newJobAdded' })
      await CareersCommands.addJob(this.currentBlock, item.id)
    },
    async onFormControlUpdate(controlName, value) {
      await this.applyCareersUpdate({ [controlName]: value })
    },
    async onJobEdited() {
      await this.fetchJobs()
      this.sendBuilderEvent('jobs', { topic: 'jobEdited' })
    },
    async onReorder({ newIndex, oldIndex }) {
      await CareersCommands.reorderJob(this.currentBlock, oldIndex, newIndex)
    },
  },
}
</script>
