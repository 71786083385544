<template>
  <TabsRoot
    class="flex-col gap-10"
    :default-value="tabs[0]"
    @update:model-value="onModelValueChange"
  >
    <TabsList class="own-tabs__list flex-row flex-shrink-0">
      <TabsIndicator class="own-tabs__indicator" />

      <TabsTrigger
        v-for="tab of tabs"
        :key="tab"
        class="own-tabs__trigger flex-col gap-1 flex-1 pa-4 no-select"
        :value="tab"
      >
        <OwnType
          :text="triggers[tab]['title']"
          el="span"
          variant="paragraph-small"
          color="secondary"
        />

        <OwnProgressSpinner v-if="triggers[tab]['loading']" size="24" />
        <OwnType
          v-else
          :text="triggers[tab]['value']"
          el="span"
          variant="subtitle"
          color="primary"
        />
      </TabsTrigger>
    </TabsList>

    <TabsContent v-for="tab of tabs" :key="tab" :value="tab">
      <Component :is="content[tab]" />
    </TabsContent>
  </TabsRoot>
</template>

<script lang="ts" setup generic="T extends readonly string[]">
import {
  TabsContent,
  TabsIndicator,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from 'radix-vue'
import type { Component } from 'vue'

import { OwnProgressSpinner } from '../OwnProgressSpinner'
import { OwnType } from '../OwnType'

import { OwnTabsTrigger } from './types'

defineProps<{
  tabs: T
  triggers: Record<T[number], OwnTabsTrigger>
  content: Record<T[number], Component>
}>()

const emit = defineEmits<{ (event: 'selected', value: T[number]): void }>()

const onModelValueChange = (newValue: T[number]) => {
  emit('selected', newValue)
}
</script>

<style lang="scss">
.own-tabs {
  &__list {
    overflow-x: auto;
    overflow-y: hidden;

    position: relative;
  }

  &__indicator {
    position: absolute;
    left: 0;
    height: 2px;
    bottom: 0;
    width: var(--radix-tabs-indicator-size);
    transform: translateX(var(--radix-tabs-indicator-position));
    transition-property: width, transform;
    transition-duration: 300ms;
    background-color: var(--misc-brand);
  }

  &__trigger {
    min-width: 150px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--background-divider);

    &[data-state='active'] {
      background-color: var(--background-secondary);
    }

    &:hover {
      background-color: var(--background-secondary);
    }
  }
}
</style>
