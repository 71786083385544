<i18n locale="en">
{
  "actions": {
    "replace": "Replace",
    "remove": "Remove",
    "load": "Upload an image",
    "select": "Select Image"
  },
  "context": {
    "editing": "Editing {label}"
  },
  "labels": {
    "preview": "Image Preview"
  }
}
</i18n>
<template>
  <div class="own-image">
    <OwnFile
      :model-value="localImage"
      accept="image/png, image/jpeg"
      :allow-delete="allowDelete"
      :disabled="disabled"
      :edit-button-replace-label="t('actions.replace')"
      :edit-button-select-label="t('actions.select')"
      :error="error"
      :hide-edit-button="hideEditButton"
      @update:model-value="onFileChange"
    >
      <template #empty>
        <PhCamera class="misc-brand" size="24" />
        <OwnType
          color="primary"
          align="center"
          :text="uploadLabel ?? t('actions.load')"
          variant="button"
        />
      </template>
      <template #preview="{ previewValue }">
        <img
          class="own-image__preview"
          :src="previewValue"
          :alt="t('labels.preview')"
        />
      </template>
    </OwnFile>

    <ImageCropper
      v-if="showImageEditor"
      :file-type="fileType"
      :image="localImage"
      :aspect-ratio="aspectRatio"
      :crop-height="cropHeight"
      :crop-width="cropWidth"
      :title="t('context.editing', { label })"
      @close="onCancel"
      @update="updateImage"
    />
  </div>
</template>
<script>
import { PhCamera } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnFile, OwnType } from '@/ui'
import { toDataUrl } from '@/utils/toDataUrl'

import ImageCropper from './ImageCropper'

export default {
  name: 'ImageInput',
  components: {
    ImageCropper,
    OwnFile,
    OwnType,
    PhCamera,
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    /** Allow removing the image (setting to null) */
    allowDelete: { type: Boolean, default: false },

    /** Aspect Ratio */
    aspectRatio: { type: Number, default: undefined },

    /** Force Crop Height */
    cropHeight: { type: Number, default: undefined },

    /** Force Crop Width */
    cropWidth: { type: Number, default: undefined },

    /** When `true`, the input will be disabled */
    disabled: { type: Boolean, default: false },

    /** Has error */
    error: { type: Boolean, default: false },

    /**
     * File type to upload. Use `'jpeg'` (the default) for photos and `'png'` for illustrations (e.g. the logo).
     * @default 'jpeg'
     */
    fileType: {
      type: String,
      default: 'jpeg',
      validator: (v) => ['jpeg', 'png'].includes(v),
    },

    /** Full height */
    hFull: { type: Boolean, default: false },

    /** Hides the edit button, clicking the image still works */
    hideEditButton: { type: Boolean, default: false },

    /** Label of image */
    label: { type: String, default: undefined },

    /** @model Image (url or base64 enc string) */
    modelValue: { type: String, default: undefined },

    /** Label for upload button */
    uploadLabel: { type: String, default: undefined },
  },
  data() {
    return {
      localImage: '',
      showImageEditor: false,
    }
  },
  computed: {
    modelImage: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  watch: {
    modelImage(newImage, oldImage) {
      if (newImage !== oldImage) {
        this.localImage = newImage
      }
    },
  },
  created() {
    const { modelImage } = this

    this.localImage = modelImage
  },
  methods: {
    onCancel() {
      this.showImageEditor = false
      this.localImage = this.modelImage
    },
    async onFileChange(file) {
      if (file) {
        this.localImage = await toDataUrl(file)
        this.showImageEditor = true
      } else {
        this.updateImage(null)
      }
    },
    updateImage(croppedImage) {
      this.localImage = ''
      this.modelImage = croppedImage
      this.showImageEditor = false
    },
  },
}
</script>
<style lang="scss" scoped>
.own-image {
  &__preview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
}
</style>
