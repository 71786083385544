<template>
  <OwnPopover
    class="own-color-picker"
    align-x="right"
    align-y="bottom"
    :slide="['x']"
    content-width="176"
    content-height="176"
    trigger-type="click"
  >
    <template #trigger="{ on }">
      <OwnInput
        v-model="previewColor"
        class="own-color-picker__input"
        :error="error"
        @update:model-value="onTextInput"
      >
        <template #prepend>
          <button v-on="on">
            <span
              class="own-color-picker__preview"
              :style="{ backgroundColor: previewColor }"
            ></span>
          </button>
        </template>
      </OwnInput>
    </template>
    <template #content="{ close }">
      <div class="own-color-picker__picker">
        <CanvasColorPicker
          @preview="onPreview"
          @commit="onColorCommit($event, close)"
        />
      </div>
    </template>
  </OwnPopover>
</template>
<script>
import { OwnInput } from '../OwnInput'
import { OwnPopover } from '../OwnPopover'

import CanvasColorPicker from './CanvasColorPicker'

const sanitizeColorString = (newValue) => {
  if (newValue.startsWith('##')) {
    return newValue.replace('##', '#')
  }

  if (!newValue.startsWith('#')) {
    return `#${newValue}`
  }

  return newValue
}

export default {
  name: 'OwnColorPicker',
  components: {
    CanvasColorPicker,
    OwnInput,
    OwnPopover,
  },
  props: {
    /**
     * Has Error
     */
    error: { type: Boolean, default: false },

    /**
     * Model Value
     * @model
     */
    modelValue: { type: String, default: '#ffffff' },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      previewColor: '',
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit(
          'update:modelValue',
          sanitizeColorString(value).toUpperCase()
        )
      },
    },
  },
  watch: {
    inputValue() {
      this.previewColor = this.inputValue
    },
  },
  created() {
    this.previewColor = this.inputValue
  },
  methods: {
    onColorCommit(color, close) {
      this.previewColor = color
      this.inputValue = color
      close()
    },
    onPreview(previewColor) {
      this.previewColor = sanitizeColorString(previewColor).toUpperCase()
    },
    onTextInput(newValue) {
      this.inputValue = newValue
    },
  },
}
</script>
<style lang="scss">
@use '@/styles/globals';

.own-color-picker {
  width: 100%;

  &__preview {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: var(--background-primary);
    display: block;
  }

  &__input {
    width: 100%;
  }

  &__picker {
    height: 176px;
    width: 176px;
    background-color: var(--white);
    border-radius: 4px;
    border: 1px solid var(--background-divider);
    padding: 4px;
  }
}
</style>
